
import { $gettext } from 'vue-gettext';
import { computed, defineComponent, nextTick, PropType, ref, watch } from 'vue';
import { defaultVersionNumber, VersionNumber, useVersionStore, Product, Component } from '@/stores/version';
import { getTranslated } from '@/translation';
import { isEqual, OptionalKeys } from '@/utils/object';
import { PopoverElement } from '../popover/BpPopoverMenu';
import { slashIcon } from '../icon/BpIcon';
import { useFormErrors } from '@/utils/form-errors';
import { useRouter } from 'vue-router';
import BpToast from '../toast/BpToasts';
import clone from '@sahnee/clone';
import BpInputVue from '../BpInput.vue';

export default defineComponent({
  name: 'bp-version-number-editor',
  props: {
    modelValue: Object as PropType<VersionNumber>,
    labelPosition: {
      type: String as PropType<'top' | 'right' | 'bottom' | 'left'>,
      default: 'left',
    },
    loading: Boolean,
    redirect: {
      type: Boolean,
      default: true,
    },
    unwrap: Boolean,
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();
    const isEdit = computed(() => router.currentRoute.value.name?.toString().endsWith('edit'));

    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const store = useVersionStore();

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { errors, setError } = useFormErrors<VersionNumber>()

    ///-------------------------------------------------------------------
    /// VERSION NUMBER
    ///-------------------------------------------------------------------

    const initialVersionNumber = ref<OptionalKeys<VersionNumber, '_id' | '_rev'>>(clone(defaultVersionNumber));
    const versionInput = ref<typeof BpInputVue>();
    const versionNumber = ref<OptionalKeys<VersionNumber, '_id' | '_rev'>>({ ...clone(defaultVersionNumber), ...clone(props.modelValue) });

    watch(() => [store.isLoading(), props.modelValue], () => {
      let parent;
      if (router.currentRoute.value.query.parent) {
        parent = store.getById((router.currentRoute.value.query.parent as string));
      }
      initialVersionNumber.value = {
        ...clone(defaultVersionNumber),
        parent_id: (parent && parent.doc_type === 'version_component') ? parent._id : '',
      }
      reset();
    }, { immediate: true });

    function versionExists() {
      return store.versionExists(versionNumber.value, versionNumber.value._id);
    }

    ///-------------------------------------------------------------------
    /// PARENT
    ///-------------------------------------------------------------------

    const parents = computed(() => {
      return store.getComponents()
        .map(component => {
          return ({ ...component, icon: store.getIcon(component) })
        }) as PopoverElement[];
    });

    const transformParent = (parent: Product | Component) => {
      const path = store.getPathById(parent._id);
      if (path.length > 0) {
        return `${store.getName(parent)} <em style="opacity: 0.5;">(${path.map(parent => store.getName(parent)).join(' > ')})</em>`;
      } else if (parent._id === 'root') {
        return `<em style="opacity: 0.5;">(${store.getName(parent)})</em>`
      }
      return store.getName(parent) || '';
    };

    function updateParentId(parentId: string) {
      // Delete meta key errors from the previous parent's meta keys
      for (const [key] of Array.from(errors.value)) {
        if (key.startsWith('meta.')) {
          errors.value.delete(key);
        }
      }

      // Check again, if the version number already exists for this new component!
      nextTick(() => versionInput.value?.checkValidity());

      versionNumber.value.parent_id = parentId;
    }

    ///-------------------------------------------------------------------
    /// RESET
    ///-------------------------------------------------------------------

    function reset() {
      if (props.modelValue && isEqual(versionNumber.value, props.modelValue)) { return }

      // Reset meta key errors from meta keys added before resetting the form
      for (const [key] of Array.from(errors.value)) {
        if (key.startsWith('meta.')) {
          errors.value.delete(key);
        }
      }

      // Reset the version number value
      versionNumber.value = { ...clone(initialVersionNumber.value), ...clone(props.modelValue) };
    }

    ///-------------------------------------------------------------------
    /// SAVE
    ///-------------------------------------------------------------------

    async function save() {
      let response;
      versionNumber.value.doc_type = 'version_number';
      if (versionNumber.value._id) {
        response = await store.update(versionNumber.value);
      } else {
        response = await store.create(versionNumber.value);
      }
      if (response?.success) {
        BpToast.show({
          color: 'green',
          title: versionNumber.value._id
            ? $gettext('Version number successfully updated')
            : $gettext('Version number successfully created'),
          content: versionNumber.value._id
            ? $gettext('The version number was successfully updated.')
            : $gettext('The version number was successfully created.'),
          icon: 'circle-check',
        });
        if (props.redirect) {
          router.replace({ name: 'admin.version.overview' });
        }
        return response.data;
      } else {
        BpToast.show({
          color: 'red',
          title: versionNumber.value._id
            ? $gettext('Failed to update version number')
            : $gettext('Failed to create version number'),
          content: versionNumber.value._id
            ? $gettext('The version number could not be updated: %{error}', { error: response?.error })
            : $gettext('The version number could not be created: %{error}', { error: response?.error }),
          icon: 'circle-check',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      errors,
      getTranslated,
      isEdit,
      parents,
      reset,
      save,
      setError,
      slashIcon,
      store,
      transformParent,
      updateParentId,
      versionExists,
      versionInput,
      versionNumber,
    }
  }
})

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDynamicComponent as _resolveDynamicComponent, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "flex-column gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_wysiwyg_editor = _resolveComponent("bp-wysiwyg-editor")!
  const _component_bp_fieldset = _resolveComponent("bp-fieldset")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.unwrap ? 'span' : 'bp-card'), {
    "fill-height": _ctx.unwrap ? undefined : true,
    "fill-width": _ctx.unwrap ? undefined : true
  }, _createSlots({
    header: _withCtx(() => [
      (!_ctx.isEdit)
        ? (_openBlock(), _createBlock(_component_translate, {
            key: 0,
            tag: "h2"
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("New version number")
            ])),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_translate, {
            key: 1,
            tag: "h2",
            "translate-params": { versionNumber: _ctx.versionNumber.version }
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode("Edit version number \"%{versionNumber}\"")
            ])),
            _: 1
          }, 8, ["translate-params"]))
    ]),
    default: _withCtx(() => [
      (_ctx.versionNumber)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            class: "bp-version-number-editor__form",
            onSubmit: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_bp_input, {
              error: _ctx.versionExists() ? _ctx.$gettext('This version number does already exist.') : undefined,
              label: _ctx.$gettext('Version'),
              "label-position": _ctx.labelPosition,
              ref: "versionInput",
              onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('version', _ctx.$gettext('Version'), $event))),
              required: "",
              maxlength: 200,
              modelValue: _ctx.versionNumber.version,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.versionNumber.version) = $event))
            }, null, 8, ["error", "label", "label-position", "modelValue"]),
            _createVNode(_component_bp_select, {
              data: _ctx.parents,
              label: _ctx.$gettext('Parent component'),
              "label-position": _ctx.labelPosition,
              transform: _ctx.transformParent,
              onChangeValid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setError('parent_id', _ctx.$gettext('Parent component'), $event))),
              required: "",
              "model-value": _ctx.versionNumber.parent_id,
              "onUpdate:modelValue": _ctx.updateParentId
            }, null, 8, ["data", "label", "label-position", "transform", "model-value", "onUpdate:modelValue"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Release date'),
              "label-position": _ctx.labelPosition,
              onChangeValid: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setError('date', _ctx.$gettext('Release date'), $event))),
              required: "",
              type: "date",
              modelValue: _ctx.versionNumber.date,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.versionNumber.date) = $event))
            }, null, 8, ["label", "label-position", "modelValue"]),
            _createVNode(_component_bp_wysiwyg_editor, {
              label: _ctx.$gettext('Changelog'),
              "label-position": _ctx.labelPosition,
              onChangeValid: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setError('changelog', _ctx.$gettext('Changelog'), $event))),
              modelValue: _ctx.versionNumber.changelog,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.versionNumber.changelog) = $event)),
              "allow-alerts": ""
            }, null, 8, ["label", "label-position", "modelValue"]),
            (Object.keys(_ctx.store.getMetaById(_ctx.versionNumber.parent_id)).length > 0)
              ? (_openBlock(), _createBlock(_component_bp_fieldset, {
                  key: 0,
                  legend: _ctx.$gettext('Meta data'),
                  "intense-border": ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.store.getMetaById(_ctx.versionNumber.parent_id)), ([key, translatedKey]) => {
                        return (_openBlock(), _createBlock(_component_bp_input, {
                          key: key,
                          indent: 1.125,
                          label: _ctx.getTranslated(translatedKey),
                          onChangeValid: ($event: any) => (_ctx.setError(`meta.${key}`, _ctx.getTranslated(translatedKey) || '', $event)),
                          required: "",
                          modelValue: _ctx.versionNumber.meta[key],
                          "onUpdate:modelValue": ($event: any) => ((_ctx.versionNumber.meta[key]) = $event)
                        }, null, 8, ["label", "onChangeValid", "modelValue", "onUpdate:modelValue"]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                }, 8, ["legend"]))
              : _createCommentVNode("", true)
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (!_ctx.unwrap)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createVNode(_component_bp_form_button, {
              type: "save",
              errors: _ctx.errors,
              action: _ctx.save
            }, null, 8, ["errors", "action"]),
            _createVNode(_component_bp_form_button, {
              type: "reset",
              action: _ctx.reset
            }, null, 8, ["action"]),
            _createVNode(_component_bp_form_button, {
              type: "cancel",
              to: { name: 'admin.version.overview' }
            })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["fill-height", "fill-width"]))
}